@tailwind base;
@tailwind components;
@tailwind utilities;

.ReactCrop__crop-selection {
  border: 2px solid white;
  border-radius: 4px;
} 

/* Custom range input styling */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: #e5e7eb;
  outline: none;
  margin: 10px 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #3b82f6;
  cursor: pointer;
  transition: background .15s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #2563eb;
}

input[type="range"]::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border: 0;
  border-radius: 50%;
  background: #3b82f6;
  cursor: pointer;
  transition: background .15s ease-in-out;
}

input[type="range"]::-moz-range-thumb:hover {
  background: #2563eb;
} 